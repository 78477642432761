/* eslint-disable */
import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  CRow, CCol, CFormInput, CFormSelect, CButton, CCard, CCardHeader, CCardBody,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilX } from '@coreui/icons';
import { format, isValid, parse, getDay, isBefore, startOfDay } from 'date-fns';

const DEFAULT_TIMES = [
  '09:00', '09:30', '10:00', '10:30', '11:00', '11:30',
  '12:00', '12:30', '13:00', '13:30', '14:00', '14:30',
  '15:00', '15:30', '16:00', '16:30', '17:00', '17:30',
  '18:00', '18:30', '19:00', '19:30', '20:00', '20:30',
  '21:00', '21:30', '22:00'
];

const DAYS_OF_WEEK = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function ExtraClosingTimes({ data, onChange, timeTable }) {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const availableTimes = useMemo(() => {
    if (!selectedDate) return DEFAULT_TIMES;

    const parsedDate = parse(selectedDate, 'yyyy-MM-dd', new Date());
    if (!isValid(parsedDate)) return DEFAULT_TIMES;

    const dayOfWeek = DAYS_OF_WEEK[getDay(parsedDate)];
    const dayTimes = timeTable[dayOfWeek];

    if (!dayTimes) return DEFAULT_TIMES;

    // Check if dayTimes has a 'timetable' property
    if (Array.isArray(dayTimes.timetable) && dayTimes.timetable.length > 0) {
      return dayTimes.timetable;
    }

    // If not, use DEFAULT_TIMES
    return DEFAULT_TIMES;

  }, [selectedDate, timeTable]);

  const sortedFutureData = useMemo(() => {
    const today = startOfDay(new Date());
    return data
      .filter(item => !isBefore(parse(item.day, 'yyyy-MM-dd', new Date()), today))
      .sort((a, b) => new Date(a.day) - new Date(b.day));
  }, [data]);

  const handleAddExtraClosing = () => {
    if (selectedDate && selectedTime) {
      const parsedDate = parse(selectedDate, 'yyyy-MM-dd', new Date());
      const parsedTime = parse(selectedTime, 'HH:mm', new Date());

      if (isValid(parsedDate) && isValid(parsedTime)) {
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');
        const formattedTime = format(parsedTime, 'HH:mm');

        const updatedData = [...data];
        const existingDateIndex = updatedData.findIndex(item => item.day === formattedDate);
        
        if (existingDateIndex !== -1) {
          if (!updatedData[existingDateIndex].hours.includes(formattedTime)) {
            updatedData[existingDateIndex].hours.push(formattedTime);
            updatedData[existingDateIndex].hours.sort();
          }
        } else {
          updatedData.push({ day: formattedDate, hours: [formattedTime] });
        }

        onChange(updatedData);
        setSelectedTime('');
      } else {
        console.error('Data o orario non validi');
      }
    }
  };

  const handleRemoveExtraClosing = (day, time) => {
    const updatedData = data.map(item => {
      if (item.day === day) {
        return {
          ...item,
          hours: item.hours.filter(hour => hour !== time)
        };
      }
      return item;
    }).filter(item => item.hours.length > 0);

    onChange(updatedData);
  };

  return (
    <>
      <h4>Orari di chiusura per giorni specifici</h4>
      <p>Seleziona giorni e orari in cui sei chiuso per periodi specifici.</p>
      <CRow className="mb-3">
        <CCol md={6}>
          <CFormInput
            type="date"
            label="Seleziona il giorno"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            min={format(new Date(), 'yyyy-MM-dd')}
          />
        </CCol>
        <CCol md={6}>
          <CFormSelect
            label="Seleziona l'orario"
            value={selectedTime}
            onChange={(e) => setSelectedTime(e.target.value)}
            options={[
              { label: 'Seleziona un orario', value: '' },
              ...availableTimes.map(time => ({ label: time, value: time }))
            ]}
          />
        </CCol>
      </CRow>
      <CButton onClick={handleAddExtraClosing} className="mb-3">Aggiungi orario di chiusura</CButton>
      {sortedFutureData.map((item) => (
        <CCard key={item.day} className="mb-3">
          <CCardHeader>{format(parse(item.day, 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy')}</CCardHeader>
          <CCardBody>
            {item.hours.map((hour) => (
              <CButton 
                key={`${item.day}-${hour}`}
                color="danger"
                size="sm"
                className="me-2 mb-2"
                onClick={() => handleRemoveExtraClosing(item.day, hour)}
              >
                {hour} <CIcon icon={cilX} />
              </CButton>
            ))}
          </CCardBody>
        </CCard>
      ))}
    </>
  );
}

ExtraClosingTimes.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.string.isRequired,
    hours: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  timeTable: PropTypes.object.isRequired,
};

export default ExtraClosingTimes;