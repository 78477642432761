import { parse, isAfter } from 'date-fns';
import toast from 'react-hot-toast';

const validateDateRange = (fromDate, toDate) => {
  // Parse the dates
  const parsedFromDate = parse(fromDate, 'yyyy-MM-dd', new Date());
  const parsedToDate = parse(toDate, 'yyyy-MM-dd', new Date());

  // Check dates format
  if (Number.isNaN(parsedFromDate.getTime()) || Number.isNaN(parsedToDate.getTime())) {
    toast.error('Errore: formato data non valido. La data deve essere in formato YYYY-MM-DD.');
    return {
      isValid: false,
      invalidField: Number.isNaN(parsedFromDate.getTime()) ? 'from_date' : 'to_date',
    };
  }

  // Check if the initial date is subsequent to the final date
  if (isAfter(parsedFromDate, parsedToDate)) {
    toast.error('Errore: la data di inizio deve essere antecedente alla data di fine.');
    return {
      isValid: false,
      invalidField: 'from_date',
    };
  }

  return { isValid: true };
};

export default validateDateRange;
