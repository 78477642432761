/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import {
  CCol, CFormInput, CFormLabel, CRow,
} from '@coreui/react';
import { useForm, Controller } from 'react-hook-form';

import ContactsService from 'src/services/api/ContactsService';
import AppList from 'src/components/ui/List/AppList';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import CitiesService from 'src/services/api/CitiesService';
import ZonesService from 'src/services/api/ZonesService';

function ContactsList() {
  const {
    control, handleSubmit, reset, getValues, formState: { errors },
  } = useForm();

  const [zonesOptions, setZonesOptions] = useState([]);

  const loadCities = (filter) => new Promise((resolve) => {
    const citiesService = new CitiesService();
    const okGetCities = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem._id, label: currentItem.city_name }
        ));
      }
      resolve(responseData);
    };
    const koGetCities = () => resolve([]);
    const filters = {};
    if (filter.length > 0) filters.search = filter;
    citiesService.getList({
      paginate: 20,
      page: 1,
      filters,
      okCallback: (res) => okGetCities(res),
      koCallback: (err) => koGetCities(err),
    });
  });

  const loadZones = () => {
    const zonesService = new ZonesService();
    const okGetZones = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem._id, label: currentItem.name }
        ));
      }
      setZonesOptions(responseData);
    };
    const koGetZones = () => setZonesOptions([]);
    zonesService.getAll({
      okCallback: (res) => okGetZones(res),
      koCallback: (err) => koGetZones(err),
    });
  };

  useEffect(() => loadZones(), []);

  const buildColumnsFn = () => [
    {
      key: 'business_name',
      label: 'Nome Cantina',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'holder',
      label: 'Titolare/i',
      sortable: true,
      _props: { scope: 'col' },
    },
    {
      key: 'registered_address',
      label: 'Indirizzo (Sede legale)',
      sortable: true,
      _props: { scope: 'col' },
    },
  ];

  const buildRowsFn = (item) => ({
    _id: item._id,
    business_name: item.business_name,
    holder: item.holder,
    registered_address: item.registered_address,
  });

  const mapListFn = (item) => ({
    _id: item._id,
    business_name: item.business_name,
    holder: item.holder || '-',
    registered_address: item.registered_address,
  });

  const formatCreationData = (data) => {
    const newData = { ...data };
    newData.registered_city_id = data.registered_city_id.value;
    // newData.commercial_ref_phone = '';
    return newData;
  };

  const creationBodyFn = () => (
    <CRow md={{ cols: 2, gutter: 2 }}>
      <CCol md={6}>
        <Controller
          name="business_name"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.business_name}
              feedback={errors?.business_name ? composeErrorFormType(errors.business_name) : null}
              type="text"
              id="contact-business_name"
              label="Nome cantina"
              placeholder="Inserisci nome cantina"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="holder"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.holder}
              feedback={errors?.holder ? composeErrorFormType(errors.holder) : null}
              type="text"
              id="contact-holder"
              label="Titolare/i"
              placeholder="Inserisci titolare/i"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="certified_email"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.certified_email}
              feedback={errors?.certified_email
                ? composeErrorFormType(errors.certified_email)
                : null}
              type="email"
              id="contact-certified_email"
              label="PEC"
              placeholder="Inserisci PEC"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="product_category"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <CFormLabel htmlFor="new-contact-product_category">Zona</CFormLabel>
              <Select
                inputId="new-contact-product_category"
                isClearable
                options={zonesOptions}
                {...field}
              />
              {errors.product_category ? <div className="invalid-feedback d-block">{composeErrorFormType(errors.product_category)}</div> : null}
            </>
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="registered_address"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.registered_address}
              feedback={errors?.registered_address
                ? composeErrorFormType(errors.registered_address)
                : null}
              type="text"
              id="contact-registered_address"
              label="Indirizzo (sede legale)"
              placeholder="Inserisci indirizzo"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="registered_city_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <>
              <CFormLabel htmlFor="new-contact-registered_city_id">Città (sede legale)</CFormLabel>
              <AsyncSelect
                inputId="new-contact-registered_city_id"
                isClearable
                defaultOptions
                loadOptions={loadCities}
                {...field}
              />
              {errors.registered_city_id ? <div className="invalid-feedback d-block">{composeErrorFormType(errors.registered_city_id)}</div> : null}
            </>
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="registered_zip_code"
          defaultValue=""
          control={control}
          rules={{ required: true, pattern: /^\d{5}$/i }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.registered_zip_code}
              feedback={errors?.registered_zip_code
                ? composeErrorFormType(errors.registered_zip_code, 'Devono essere 5 cifre numeriche')
                : null}
              type="number"
              id="contact-registered_zip_code"
              label="CAP (sede legale)"
              placeholder="Inserisci CAP"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="vat_code"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.vat_code}
              feedback={errors?.vat_code ? composeErrorFormType(errors.vat_code) : null}
              type="text"
              id="contact-vat_code"
              label="Codice fiscale"
              placeholder="Inserisci codice fiscale"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="commercial_ref_phone"
          defaultValue=""
          control={control}
          rules={{ required: true, pattern: /^[+]?\d{3,}$/i }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.commercial_ref_phone}
              feedback={errors?.commercial_ref_phone
                ? composeErrorFormType(errors.commercial_ref_phone)
                : null}
              type="text"
              id="contact-commercial_ref_phone"
              label="Telefono"
              placeholder="Inserisci numero di telefono"
              {... field}
            />
          )}
        />
      </CCol>
      <CCol md={6}>
        <Controller
          name="commercial_ref_email"
          defaultValue=""
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <CFormInput
              invalid={!!errors.commercial_ref_email}
              feedback={errors?.commercial_ref_email
                ? composeErrorFormType(errors.commercial_ref_email)
                : null}
              type="email"
              id="contact-commercial_ref_email"
              label="Email"
              placeholder="Inserisci email"
              {... field}
            />
          )}
        />
      </CCol>
    </CRow>
  );

  const exportOptions = [
    { value: 'invoicing', label: 'Fatturato cantine' },
    { value: 'amounts', label: 'Dovuto cantine' },
    { value: 'bookings', label: 'Prenotazioni generali cantine' },
  ];

  const advancedExportModalProps = {
    title: 'Esportazione dati avanzati',
    descriptions: {
      invoicing: 'Funziona esattamente come l\'export "prenotazioni generali cantine", ma è possibile inserire un filtro di soglia minima e sono visibili solo i guadagni netti delle cantine.\nL\'unico scopo di questo export è quello di verificare quali cantine hanno raggiunto la soglia in un dato periodo.',
      amounts: 'Include tutte le prenotazioni effettuate nel periodo selezionato. Supponendo che il periodo di riferimento sia dal 1° settembre al 30 settembre, ecco alcuni esempi:\n\n• Se acquisto un tour il 13/09 per la data del 14/09, i relativi dati saranno inclusi in questo export.\n•Sono inclusi anche i dati di un tour prenotato il 21/07 ed effettuato il 01/09, poiché rientra nel periodo considerato.\n• Non saranno invece inclusi i dati di un tour acquistato il 19/09 ma previsto per il 02/11, questo infatti sarà visibile nell\'export di novembre.',
      bookings: 'Include tutte le prenotazioni effettuate nel periodo selezionato. Supponendo che il periodo di riferimento sia dal 1° settembre al 30 settembre, ecco alcuni esempi:\n\n• Se un tour viene acquistato il 13 settembre per la data del 14 settembre, la prenotazione sarà inclusa in questo export.\n• Analogamente, sarà inclusa anche una prenotazione effettuata il 19 settembre per una data futura, come il 2 novembre.',
    },
    fields: [
      {
        id: 'exportType',
        label: 'Tipo di esportazione',
        type: 'select',
        options: exportOptions,
        required: true,
      },
      {
        id: 'from_date',
        label: 'Data inizio',
        type: 'date',
        required: true,
      },
      {
        id: 'to_date',
        label: 'Data fine',
        type: 'date',
        required: true,
        max: new Date().toISOString().split('T')[0],
      },
      {
        id: 'threshold',
        label: 'Soglia minima',
        type: 'number',
        required: true,
        min: 0,
        conditional: (values) => values.exportType === 'invoicing',
      },
    ],
    submitButtonText: 'Esporta',
  };

  return (
    <section id="contacts">
      <AppList
        sectionId="contacts"
        sectionTitle="Lista Cantine"
        SectionServiceClass={ContactsService}
        sectionPath="/winery"
        mapListFn={mapListFn}
        buildColumnsFn={buildColumnsFn}
        buildRowsFn={buildRowsFn}
        creationTitle="Nuovo Contatto"
        creationBodyFn={() => creationBodyFn()}
        evalCreation={handleSubmit}
        clearCreationModel={() => reset({})}
        formatCreationData={formatCreationData}
        initialSortField="business_name"
        isExportable
        isAdvancedExportable
        advancedExportModalProps={advancedExportModalProps}
      />
    </section>
  );
}

export default ContactsList;
