/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useForm, Controller } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import {
  CForm, CCol, CFormInput, CRow, CFormLabel,
} from '@coreui/react';
import toast from 'react-hot-toast';

import CitiesService from 'src/services/api/CitiesService';
import ContactsService from 'src/services/api/ContactsService';
import ZonesService from 'src/services/api/ZonesService';
import VercelService from 'src/services/VercelService';
import buttonActionTypes from 'src/utils/buttonActionTypes';

import AppDetail from 'src/components/ui/Detail/AppDetail';
import AppLoadingSpinner from 'src/components/ui/AppLoadingSpinner';
import Gallery from 'src/components/ui/Images/Gallery/Gallery';
import composeErrorFormType from 'src/utils/composeErrorFormType';
import AppMultiData from 'src/components/ui/MultiData/AppMultiData';
import ServicesCheckbox from 'src/components/ui/ServicesCheckbox/ServicesCheckbox';
import WinesForm from './Wines/WinesForm';

function ContactsDetail() {
  const { id } = useParams();
  const userGroup = useSelector((state) => state.user.value.user_group);
  const [state, setState] = useState({
    loading: true,
    error: null,
    loadingDefaultCity: false,
    defaultCity: null,
    cityValue: null,
    model: null,
  });
  const [zonesOptions, setZonesOptions] = useState([]);
  const {
    control, handleSubmit, reset, getValues, setValue, formState: { errors },
  } = useForm({
    defaultValues: {
      business_name: '',
      holder: '',
      certified_email: '',
      registered_address: '',
      registered_city_id: {},
      registered_zip_code: '',
      vat_code: '',
      commercial_ref_phone: '',
      commercial_ref_email: '',
      contact_category_id: '',
    },
  });

  const vercelService = new VercelService();

  const formatModel = (response) => {
    const contactResponseData = { ...response?.data || {} };
    const contactModelData = {};
    contactModelData.business_name = contactResponseData.business_name;
    contactModelData.product_category = contactResponseData.product_category;
    contactModelData.contact_category_id = contactResponseData.contact_category_id;
    contactModelData.holder = contactResponseData.holder;
    contactModelData.certified_email = contactResponseData.certified_email;
    contactModelData.registered_address = contactResponseData.registered_address;
    contactModelData.registered_city_id = contactResponseData.registered_city_id;
    contactModelData.registered_city = null;
    contactModelData.registered_zip_code = contactResponseData.registered_zip_code;
    contactModelData.vat_code = contactResponseData.vat_code;
    contactModelData.commercial_ref_phone = contactResponseData.commercial_ref_phone || '';
    contactModelData.commercial_ref_email = contactResponseData.commercial_ref_email || '';
    contactModelData.wines = contactResponseData?.wines || [];
    contactModelData.services = contactResponseData?.services || [];
    contactModelData.experience_kinds = contactResponseData?.experience_kinds || [];
    contactModelData.available_activities = contactResponseData?.available_activities || [];

    return contactModelData;
  };

  const loadZones = () => {
    const zonesService = new ZonesService();
    const okGetZones = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem._id, label: currentItem.name }
        ));
      }
      setZonesOptions(responseData);
    };
    const koGetZones = () => setZonesOptions([]);
    zonesService.getAll({
      okCallback: (res) => okGetZones(res),
      koCallback: (err) => koGetZones(err),
    });
  };

  const loadDefaultCity = (defaultCityId) => {
    const newState = { ...state };
    newState.loadingDefaultCity = true;
    setState(newState);
    const citiesService = new CitiesService();
    const okGetCities = (response) => {
      const responseData = response.data
        ? { value: response.data._id, label: response.data.city_name }
        : null;
      if (responseData) {
        newState.loadingDefaultCity = false;
        newState.defaultCity = { ...responseData };
        newState.cityValue = { ...responseData };
      }
      setState(newState);
      setValue('registered_city', responseData);
    };
    const koGetCities = () => [];
    citiesService.getItem(defaultCityId, okGetCities, koGetCities);
  };

  useEffect(() => {
    loadZones();
    if (id !== null && typeof id !== 'undefined') {
      const contactService = new ContactsService();
      const okGetDetails = (response) => {
        const contactModelData = formatModel(response);

        reset(contactModelData);
        setState({ ...state, loading: false, model: contactModelData });
      };

      const koGetDetails = (error) => {
        const errorMessage = error?.data?.message || 'Nessun errore';
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      contactService.getItem(id, okGetDetails, koGetDetails);
    }
  }, []);

  useEffect(() => {
    if (state.loading === false &&
        state.loadingDefaultCity === false &&
        state.model &&
        state.model.registered_city_id !== '' &&
        state.defaultCity === null) {
      loadDefaultCity(state.model.registered_city_id);
    }
  }, [state]);

  const loadCities = (filter) => new Promise((resolve) => {
    const citiesService = new CitiesService();
    const okGetCities = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem._id, label: currentItem.city_name }
        ));
      }
      resolve(responseData);
    };
    const koGetCities = () => resolve([]);
    const filters = {};
    if (filter.length > 0) filters.search = filter;
    citiesService.getList({
      paginate: 5,
      page: 1,
      filters,
      okCallback: (res) => okGetCities(res),
      koCallback: (err) => koGetCities(err),
    });
  });

  const saveAction = (type) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        const contactModelData = formatModel(response);
        reset(contactModelData);
        setState({ ...state, loading: false, model: contactModelData });
        loadDefaultCity(contactModelData.registered_city_id);
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error, errorToShow: response?.error });
        reject(new Error(response.data.message));
      };

      const contactService = new ContactsService();
      const formData = getValues();
      const mappedFormData = { ...formData };
      mappedFormData.wines = formData.wines.map(
        (wine) => ({ name: wine.name, tag_id: wine.wine_type_tag._id }),
      );
      mappedFormData.experience_kinds = [];
      formData.experience_kinds.forEach((data) => {
        mappedFormData.experience_kinds.push(data._id);
      });
      mappedFormData.services = [];
      formData.services.forEach((data) => {
        mappedFormData.services.push(data._id);
      });
      mappedFormData.available_activities = [];
      formData.available_activities.forEach((data) => {
        mappedFormData.available_activities.push(data._id);
      });
      switch (type) {
        case 'publishNow':
          vercelService.deploy()
            .then(() => {
              resolve({ message: 'Pubblicazione avvenuta con successo!' });
            })
            .catch((err) => {
              reject(new Error(err.data.message));
            });
          break;
        case 'save':
          contactService.updateItem(id, mappedFormData, okEditCallback, koEditCallback);
          break;
        default:
          contactService.updateItem(id, mappedFormData, okEditCallback, koEditCallback);
      }
    });

    toast.promise(savePromise, {
      loading: 'Attendere, salvando le modifiche...',
      // eslint-disable-next-line arrow-body-style
      success: (data) => { return (data?.message || 'Operazione eseguita con successo!'); },
      // eslint-disable-next-line arrow-body-style
      error: (err) => { return err ? err.toString() : 'Ops, si è verificato un errore!'; },
    }, {
      success: {
        duration: 5000,
      },
      error: {
        duration: 5000,
      },
    });
  };

  const insertWine = (data, formProps) => {
    const newModel = { ...getValues() };
    const formatData = { ...data };
    if (!formatData.type) {
      formatData.type = data.wine_type_tag?.tag;
    }
    delete formatData.idx;
    if (!newModel.wines) newModel.wines = [];
    newModel.wines.push(formatData);
    setValue('wines', newModel.wines);
    setState({ ...state, model: newModel });
    formProps.closeModal();
  };

  const editWine = (data, formProps) => {
    const newModel = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      const formatData = { ...data };
      delete formatData.idx;
      newModel.wines[data.idx] = (formatData);
      setValue('wines', newModel.wines);
      setState({ ...state, model: newModel });
    }
    formProps.closeModal();
  };

  const deleteWine = (data) => {
    const newModel = { ...getValues() };
    if (typeof data.idx === 'number' && data.idx >= 0) {
      newModel.wines.splice(data.idx, 1);
      setValue('wines', newModel.wines);
      setState({ ...state, model: newModel });
    }
  };

  const updateContactSelections = (field, selectionData) => {
    const newModel = getValues();
    newModel[field] = selectionData;
    setValue(field, selectionData);
    setState({ ...state, model: newModel });
  };

  if (state.loading === true) return <AppLoadingSpinner />;

  if (state.error) return <p>NO DATA</p>;

  return (
    <CForm>
      {state.errorToShow && (
        <div className="alert alert-danger" role="alert">
          {state.errorToShow?.message || 'Errore!'}
        </div>
      )}
      <AppDetail
        sectionId="contacts"
        saveAction={saveAction}
        hideActions={[buttonActionTypes.PUBLISH]}
        name={getValues('business_name')}
        tabsHeaders={[
          {
            index: 'legal-tab',
            label: 'INFORMAZIONI LEGALI',
          },
          {
            index: 'winery-tab',
            label: 'INFORMAZIONI CANTINA',
          },
          {
            index: 'wine-tab',
            label: 'VINI',
          },
          {
            index: 'services-tab',
            label: 'SERVIZI',
          },
          {
            index: 'experiences-tab',
            label: 'TIPOLOGIA ESPERIENZA',
          },
          {
            index: 'activities-tab',
            label: 'ATTIVITÀ IN ZONA',
          },
        ]}
        tabsContents={
          [
            {
              index: 'legal-tab',
              content: (
                <CRow className="g-3">
                  <CCol md={12}>
                    <h4>Informazione legale</h4>
                    <p>
                      Inserisci i dati legali della cantina
                    </p>
                    <hr className="mb-4" />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="business_name"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          onChange={field.onChange}
                          invalid={!!errors.business_name}
                          feedback={errors?.business_name
                            ? composeErrorFormType(errors.business_name)
                            : null}
                          type="text"
                          id="contact-business_name"
                          label="Nome Cantina"
                          placeholder="Inserisci nome cantina"
                          {...field}
                        />
                      )}
                    />
                    <p>
                      {errors?.business_name
                        ? composeErrorFormType(errors.business_name)
                        : ''}
                    </p>
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="holder"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.holder}
                          feedback={errors?.holder ? composeErrorFormType(errors.holder) : null}
                          type="text"
                          id="contact-holder"
                          label="Nome e Cognome Legale rappresentante"
                          placeholder="Inserisci titolare/i"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="commercial_ref_email"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.commercial_ref_email}
                          feedback={errors?.commercial_ref_email
                            ? composeErrorFormType(errors.commercial_ref_email)
                            : null}
                          type="email"
                          id="contact-commercial_ref_email"
                          label="Indirizzo email"
                          placeholder="Inserisci indirizzo email"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="certified_email"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.certified_email}
                          feedback={errors?.certified_email
                            ? composeErrorFormType(errors.certified_email)
                            : null}
                          type="email"
                          id="contact-certified_email"
                          label="PEC"
                          placeholder="Inserisci PEC"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="registered_address"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.registered_address}
                          feedback={errors?.registered_address
                            ? composeErrorFormType(errors.registered_address)
                            : null}
                          type="text"
                          id="contact-registered_address"
                          label="Indirizzo (cantina)"
                          placeholder="Inserisci indirizzo"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="registered_city"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <CFormLabel htmlFor="new-contact-registered_city">Città (cantina)</CFormLabel>
                          <AsyncSelect
                            inputId="new-contact-registered_city"
                            isClearable
                            defaultOptions
                            value={state.defaultCity}
                            loadOptions={loadCities}
                            {...field}
                          />
                          {errors.registered_city_id ? <div className="invalid-feedback d-block">{composeErrorFormType(errors.registered_city)}</div> : null}
                        </>
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="registered_zip_code"
                      defaultValue=""
                      control={control}
                      rules={{ required: true, pattern: /^\d{5}$/i }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.registered_zip_code}
                          feedback={errors?.registered_zip_code
                            ? composeErrorFormType(errors.registered_zip_code, 'Devono essere 5 cifre numeriche')
                            : null}
                          type="number"
                          id="contact-registered_zip_code"
                          label="CAP (cantina)"
                          placeholder="Inserisci CAP"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="vat_code"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.vat_code}
                          feedback={errors?.vat_code ? composeErrorFormType(errors.vat_code) : null}
                          type="text"
                          id="contact-vat_code"
                          label="Partita IVA o Codice Fiscale"
                          placeholder="Inserisci partita IVA"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  {/* <CCol md={6}>
                    <Controller
                      name="tax_code"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.tax_code}
                          feedback={errors?.tax_code ? composeErrorFormType(errors.tax_code) : null}
                          type="text"
                          id="contact-tax_code"
                          label="Codice fiscale"
                          placeholder="Inserisci codice fiscale"
                          {... field}
                        />
                      )}
                    />
                  </CCol> */}
                  <CCol md={6}>
                    <Controller
                      name="commercial_ref_phone"
                      defaultValue=""
                      control={control}
                      rules={{ required: true, pattern: /^[+]?\d{3,}$/i }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.phone}
                          feedback={errors?.phone
                            ? composeErrorFormType(errors.phone)
                            : null}
                          type="text"
                          id="contact-phone"
                          label="Telefono"
                          placeholder="Inserisci numero di telefono"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  {/* <CCol md={6}>
                    <Controller
                      name="mobile_phone"
                      defaultValue=""
                      control={control}
                      rules={{ required: true, pattern: /^[+]?\d{3,}$/i }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.mobile_phone}
                          feedback={errors?.mobile_phone
                            ? composeErrorFormType(errors.mobile_phone)
                            : null}
                          type="text"
                          id="contact-mobile_phone"
                          label="Cellulare"
                          placeholder="Inserisci numero di cellulare"
                          {... field}
                        />
                      )}
                    />
                  </CCol> */}
                  <CCol md={6}>
                    <Controller
                      name="iban_code"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.iban_code}
                          feedback={errors?.iban_code
                            ? composeErrorFormType(errors.iban_code)
                            : null}
                          type="text"
                          id="contact-iban_code"
                          label="IBAN Cantina"
                          placeholder="Inserisci IBAN cantina"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="swift_bic"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.swift_bic}
                          feedback={errors?.swift_bic
                            ? composeErrorFormType(errors.swift_bic)
                            : null}
                          type="text"
                          id="contact-swift_bic"
                          label="Banca e SWIFT BIC"
                          placeholder="Inserisci banca e SWIFT BIC"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'winery-tab',
              content: (
                <CRow className="g-3">
                  <CCol md={12}>
                    <h4>Informazione della cantina</h4>
                    <p>
                      Inserisci i dati della cantina
                    </p>
                    <hr className="mb-4" />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="business_name"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.winery_name}
                          feedback={errors?.winery_name
                            ? composeErrorFormType(errors.winery_name)
                            : null}
                          type="text"
                          id="contact-business_name"
                          label="Nome cantina"
                          placeholder="Inserisci nome cantina"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="registered_address"
                      defaultValue=""
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <CFormInput
                          invalid={!!errors.winery_address}
                          feedback={errors?.winery_address
                            ? composeErrorFormType(errors.winery_address)
                            : null}
                          type="text"
                          id="contact-registered_address"
                          label="Indirizzo Cantina"
                          placeholder="Inserisci indirizzo"
                          {... field}
                        />
                      )}
                    />
                  </CCol>
                  <CCol md={6}>
                    <Controller
                      name="product_category"
                      control={control}
                      rules={{ required: true }}
                      render={({ field }) => (
                        <>
                          <CFormLabel htmlFor="new-contact-product_category">Zona Vitivinicola</CFormLabel>
                          <Select
                            inputId="new-contact-product_category"
                            isClearable
                            defaultOptions
                            options={zonesOptions}
                            {...field}
                          />
                          {errors.product_category ? <div className="invalid-feedback d-block">{composeErrorFormType(errors.product_category)}</div> : null}
                        </>
                      )}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'wine-tab',
              content: (
                <>
                  <h4>I Vini della Cantina</h4>
                  <p>
                    Inserisci le tipologie di vini proposti durante le
                    degustazioni. Ti ricordiamo di scrivere solamente
                    la tipologia di vino e la denominazione. Non è
                    possibile scrivere il nome commerciale del vostro
                    vino.
                  </p>
                  <hr className="mb-4" />
                  <AppMultiData
                    buttonAddString="Aggiungi Nuovo Vino"
                    userGroup={userGroup}
                    className="mb-4"
                    title="Vini"
                    item="Vino"
                    modalSize="xl"
                    formId="vino-cantina"
                    createFormComponent={(CreateFormProps) => WinesForm({
                      formId: 'create_vino-cantina',
                      submit: (data) => insertWine(data, CreateFormProps),
                      parentProps: {
                        show: CreateFormProps.show,
                      },
                    })}
                    editFormComponent={(EditFormProps) => WinesForm({
                      formId: 'edit_vino-cantina',
                      submit: (data) => editWine(data, EditFormProps),
                      parentProps: {
                        show: EditFormProps.show,
                        target: EditFormProps.target,
                      },
                    })}
                    deleteFunction={(deleteProps) => deleteWine({
                      idx: deleteProps.idx,
                    })}
                    data={
                      state?.model?.wines.map((item, idx) => ({
                        idx,
                        name: item.name,
                        type: item.wine_type_tag?.tag,
                        tag_id: item?.tag_id || item.wine_type_tag?._id,
                        image_path: item?.wine_type_tag?.media_contents?.[0]?.path,
                      })) || []
                    }
                    columns={[
                      { index: 'name', type: 'text' },
                      { index: 'type', type: 'text' },
                    ]}
                  />
                </>
              ),
            },
            {
              index: 'services-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Servizi della Cantina</h4>
                    <p>
                      Seleziona i servizi offerti dalla tua cantina che vuoi
                      mostrare nella tua pagina di presentazione del
                      tour.
                    </p>
                    <hr className="mb-4" />
                    <ServicesCheckbox
                      serviceType="tourServices"
                      data={state?.model?.services}
                      onChange={(value) => updateContactSelections('services', value)}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'experiences-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Tipologia dell&apos;esperienza</h4>
                    <p>
                      Seleziona le diverse esperienze disponibili presso
                      la tua cantina che vuoi mostrare nella tua pagina di
                      presentazione del tour.
                    </p>
                    <hr className="mb-4" />
                    <ServicesCheckbox
                      serviceType="experience_kinds"
                      data={state?.model?.experience_kinds}
                      onChange={(value) => updateContactSelections('experience_kinds', value)}
                    />
                  </CCol>
                </CRow>
              ),
            },
            {
              index: 'activities-tab',
              content: (
                <CRow className="g-3">
                  <CCol>
                    <h4>Attività disponibili </h4>
                    <p>
                      Seleziona le diverse attività disponibili presso la
                      tua cantina oppure nella tua zona, che vuoi
                      mostrare nella tua pagina di presentazione del
                      tour.
                    </p>
                    <hr className="mb-4" />
                    <ServicesCheckbox
                      serviceType="available_activities"
                      data={state?.model?.available_activities}
                      onChange={(value) => updateContactSelections('available_activities', value)}
                    />
                  </CCol>
                </CRow>
              ),
            },
          ]
        }
      />
    </CForm>
  );
}

export default ContactsDetail;
